import "core-js/modules/es.array.push.js";
import Vue from 'vue';
import Router from 'vue-router';
import store from '@store';

/* Layout */
// import Layout from '@views/layout/layout'

/* Router Modules */
// import organizationRouter from './modules/organization'
import homeRouter from './modules/home';
import appointmentRouter from './modules/appointment';
import directionsRouter from './modules/directions';
import cleanRouter from './modules/clean';
import maintainRouter from './modules/maintain';
import customRouter from './modules/custom';
import houseRouter from './modules/house';
import signContractRouter from './modules/signContract';
// import fileRouter from './modules/file'
// import userRouter from './modules/user'
import checkOutRouter from './modules/checkOut';
import maintenanceRouter from '@router/modules/maintenance';
import assetsRouter from './modules/assets';
import billRouter from './modules/bill';
import dailyReportRouter from './modules/dailyReport';
import couponRouter from './modules/coupon';
import roomChangeRouter from './modules/roomChange';
import paymentRouter from './modules/payment';
import expenseRouter from './modules/expense';
import SprintRouter from '@router/modules/chongci';
import ChongciRouter from '@router/modules/chongci';
import priceBreak from '@router/modules/priceBreak';
Vue.use(Router);
let routerTrigger = false;
class MyRouter extends Router {
  push(location, onComplete, onAbort) {
    routerTrigger = true;
    store.dispatch('keepAlive/PushRoute', super.resolve(location).resolved);
    super.push(location, onComplete, onAbort);
  }
  replace(location, onComplete, onAbort) {
    routerTrigger = true;
    store.dispatch('keepAlive/ReplaceRoute', super.resolve(location).resolved);
    super.replace(location, onComplete, onAbort);
  }
  go(n) {
    if (n !== 0) {
      routerTrigger = true;
      store.dispatch('keepAlive/PopRoute', {
        count: n
      });
      super.go(n);
    } else {
      window.location.reload();
    }
  }
}

/**
 path: string;
 component?: Component;
 name?: string; // 命名路由
 components?: { [name: string]: Component }; // 命名视图组件
 redirect?: string | Location | Function;
 props?: boolean | Object | Function;
 meta : {
    title: 'title' // 页面标题
    noCache: true // 是否keep-alive
    requireAuth: true // 是否需要登录权限
  }
 children?: Array<RouteConfig>; // 嵌套路由
 **/
export const constantRoutes = [{
  path: '/redirect',
  component: () => import('@views/redirect/index'),
  name: 'Redirect'
}, {
  path: '/login',
  component: () => import('@views/login/login'),
  name: 'Login'
}, {
  path: '/401',
  component: () => import('@views/401'),
  name: '401'
}, {
  path: '/404',
  component: () => import('@views/404'),
  name: '404'
}, {
  path: '/error',
  component: () => import('@views/error'),
  name: 'error'
}, {
  path: '/develop',
  component: () => import('@views/develop'),
  name: 'develop'
}, {
  path: '/',
  redirect: '/redirect'
}, homeRouter, appointmentRouter, directionsRouter, cleanRouter, maintainRouter, customRouter, houseRouter, signContractRouter,
// fileRouter,
// userRouter,
checkOutRouter, maintenanceRouter, assetsRouter, billRouter, dailyReportRouter, couponRouter, roomChangeRouter, paymentRouter, expenseRouter, ChongciRouter, priceBreak, {
  path: '*',
  redirect: '/404'
}];
export const asyncRoutes = [];
export const router = new MyRouter({
  // mode: 'history', // require service support
  scrollBehavior: () => ({
    y: 0
  }),
  routes: constantRoutes
});
export default router;
router.afterEach((to, from) => {
  if (to.matched.length > 0 && store.state.keepAlive.records.length === 0) {
    store.dispatch('keepAlive/PushRoute', to);
  } else if (!routerTrigger && to.fullPath) {
    store.dispatch('keepAlive/PopRoute', {
      path: to.fullPath
    });
  }
  routerTrigger = false;
});