import Layout from '@views/layout/layout';
const cleanRouter = {
  path: '/clean',
  component: Layout,
  name: 'Clean',
  redirect: '/clean/list',
  meta: {
    title: '保洁记录',
    noCache: true,
    requireAuth: true
  },
  children: [{
    path: 'list',
    name: 'CleanList',
    components: {
      default: () => import('@views/clean/list')
    },
    props: {
      default: route => ({
        status: route.query.status
      })
    },
    meta: {
      title: '保洁记录',
      noCache: true,
      requireAuth: true
    }
  }, {
    path: 'detail',
    name: 'CleanDetail',
    components: {
      default: () => import('@views/clean/detail')
    },
    props: {
      default: route => ({
        id: route.query.id
      })
    },
    meta: {
      title: '保洁详情',
      noCache: true,
      requireAuth: true
    }
  }, {
    path: 'send',
    name: 'CleanSend',
    components: {
      default: () => import('@views/clean/send')
    },
    props: {
      default: route => ({
        id: route.query.id
      })
    },
    meta: {
      title: '发起保洁',
      noCache: true,
      requireAuth: true
    }
  }, {
    path: 'payment',
    name: 'CleanPayment',
    components: {
      default: () => import('@views/clean/payment')
    },
    props: {
      default: route => ({
        id: route.query.id
      })
    },
    meta: {
      title: '支付详情',
      noCache: true,
      requireAuth: true
    }
  }]
};
export default cleanRouter;